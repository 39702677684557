<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar video" : "Cadastrar video" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Titulo*"
                :disabled="enabledInputs"
                v-model="data.name"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Video ID*"
                :disabled="enabledInputs"
                v-model="data.videoId"
                :error-messages="errors.videoId"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                :items="cursos"
                v-model="data.curso_id"
                item-value="id"
                item-text="titulo"
                label="Curso"
                :error-messages="errors.curso_id"
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import instanceAxios from "../../plugins/axios";

export default {
  name: "UserForm",

  mounted: async function() {
    const id = this.$route.params.id;
    this.indexCursos();
    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }
  },

  components: {},

  data: () => ({
    cursos: [],
    data: {},
    errors: {},
    listRoles: [],
    loading: false,
    enabledInputs: false,
    ComercialService: new ComercialService(""),
    status: ["Cliente Fechado", "Proposta Enviada", "Agendar Contato"],
  }),

  methods: {
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        const id = this.data.id !== undefined ? "/" + this.data.id : "";
        if (id) {
          await instanceAxios.put("videos" + id, this.data);
        } else {
          await instanceAxios.post("videos", this.data);
        }

        ToastService("Ação realizada com sucesso.", "success");
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    async indexCursos() {
      try {
        const res = await instanceAxios.get("cursos");
        this.cursos = res.data;
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    getDate(payload) {
      this.data.data_retorno = payload.date;
    },

    extrairIdDoVideo(url) {
      // Verifica se o URL é um link de incorporação do YouTube
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);

      // Se houver correspondência, retorna o ID do vídeo
      if (match) {
        return match[1];
      } else {
        // Caso contrário, retorna null ou uma mensagem de erro, dependendo dos requisitos
        return null;
      }
    },

    async showData(id) {
      try {
        this.data = await this.ComercialService.show("videos/" + id);
        this.data.videoId = this.extrairIdDoVideo(this.data.link);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.back();
    },
  },
};
</script>
